import { yupResolver } from "@hookform/resolvers/yup"
import { Box, useTheme } from "@mui/material"
import React from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { NewCollectionBlockchainEnum } from "../../core/api"
import { ACCEPT_FILE_TYPE } from "../../core/const/acceptFileType"
import { collectionFormSchema } from "../../core/const/formSchema"
import { initialCollectionForm } from "../../core/const/initialFormData"
import { mainNetOptions, testNetOptions } from "../../core/const/Options"
import { CreateCollectionRequest } from "../../core/type/Request"
import { getCustomConfig } from "../../core/utils/getCustomConfig"
import { CommonSelection } from "../common/form/CommonSelection"
import { CommonTextField } from "../common/form/CommonTextField"
import { ImageUpload } from "../common/form/ImageUpload"
import { PrimaryButton } from "../common/PrimaryButton"

export type CreateCollectionDetailProps = {
  createCollection: (form: CreateCollectionRequest) => void
  loading: string
}

export const CreateCollectionDetail: React.FunctionComponent<CreateCollectionDetailProps> =
  ({ createCollection, loading }) => {
    const { t } = useTranslation()
    const { palette } = useTheme()

    const categoriesOptions = getCustomConfig().categoriesOptions

    const {
      handleSubmit,
      control,
      formState: { errors },
    } = useForm<CreateCollectionRequest>({
      mode: "onBlur",
      reValidateMode: "onBlur",
      resolver: yupResolver(collectionFormSchema),
      defaultValues: initialCollectionForm,
    })

    const renderFormControl = (
      title: string,
      hint: string,
      required: boolean,
      children: JSX.Element
    ) => {
      return (
        <div className="c-form__bl">
          <div
            className={`c-form__bl--label ${required ? "form-required" : ""}`}
          >
            {title}
            {hint && (
              <Box
                sx={{ color: palette.text.secondary }}
                className="c-form__bl--note mx-auto mb-1"
              >
                {hint}
              </Box>
            )}
          </div>
          <div className="c-form__bl--input mx-auto mb-4">{children}</div>
        </div>
      )
    }

    return (
      <div className="c-container mx-auto w-full px-5 pt-14 pb-28">
        <div className="c-form__head">
          <div>{t("str_create_collection")}</div>
          <p className="c-form__head--note mb-0">{t("str_required")}</p>
        </div>

        {renderFormControl(
          t("str_logo_image"),
          t("str_logo_image_hint"),
          true,
          <ImageUpload
            accept={ACCEPT_FILE_TYPE.toString()}
            errors={errors}
            control={control}
            name="logo_image"
            width={175}
            height={175}
          />
        )}

        {renderFormControl(
          t("str_feature_image"),
          t("str_feature_image_hint"),
          true,
          <ImageUpload
            accept={ACCEPT_FILE_TYPE.toString()}
            errors={errors}
            control={control}
            name="feature_image"
            width={170}
            height={110}
            borderRadius={0}
          />
        )}

        {renderFormControl(
          t("str_banner_image"),
          t("str_banner_image_hint"),
          true,
          <ImageUpload
            name="banner_image"
            control={control}
            errors={errors}
            accept={ACCEPT_FILE_TYPE.toString()}
            width={700}
            height={175}
            borderRadius={0}
          />
        )}

        {renderFormControl(
          t("str_collection_name"),
          t(""),
          true,
          <CommonTextField
            type="text"
            placeholder={t("str_collection_name_placeholder")}
            variant="outlined"
            name="name"
            control={control}
            errors={errors}
            fullWidth
          />
        )}

        {renderFormControl(
          t("str_collection_description"),
          t(""),
          true,
          <CommonTextField
            type="text"
            placeholder={t("str_collection_description_placeholder")}
            variant="outlined"
            name="descript"
            control={control}
            errors={errors}
            multiline
            rows={7}
            fullWidth
          />
        )}

        {renderFormControl(
          t("str_category"),
          t(""),
          true,
          <CommonSelection
            name="category"
            control={control}
            errors={errors}
            variant="outlined"
            placeholder={t("str_category_placeholder")}
            native={true}
            fullWidth
          >
            {categoriesOptions.map((item, index) => {
              return (
                <option value={item.value} key={index}>
                  {t(item.label)}
                </option>
              )
            })}
          </CommonSelection>
        )}

        {renderFormControl(
          t("str_blockchain"),
          t(""),
          true,
          <CommonSelection
            name="blockchain"
            control={control}
            errors={errors}
            variant="outlined"
            placeholder={t("str_blockchain_placeholder")}
            native={true}
            fullWidth
          >
            {(process.env.GATSBY_CHAIN_TYPE === "test"
              ? testNetOptions
              : mainNetOptions
            )
              .filter(item =>
                getCustomConfig().allowCrypto.includes(
                  item.value as NewCollectionBlockchainEnum
                )
              )
              .map((item, index) => {
                return (
                  <option value={item.value} key={index}>
                    {t(item.label)}
                  </option>
                )
              })}
          </CommonSelection>
        )}
        <div className="mb-10" />

        <div className="c-form__submit">
          <PrimaryButton
            sx={{
              height: 70,
              width: "100%",
              maxWidth: 378,
              fontSize: 21,
              fontWeight: "normal",
            }}
            variant="contained"
            onClick={handleSubmit(createCollection)}
            disabled={!!loading}
          >
            {loading || t("str_create_collection_btn")}
          </PrimaryButton>
        </div>
      </div>
    )
  }
