import * as React from "react"
import { useTranslation } from "react-i18next"
import { CreateCollection } from "../components/collection/CreateCollection"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { getCustomConfig } from "../core/utils/getCustomConfig"

const CreateCollectionPage: React.FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo
        title={`${t("str_create_collection")} | ${
          getCustomConfig().title_suffix
        }`}
        favicon={getCustomConfig().favicon}
      />
      <CreateCollection />
    </Layout>
  )
}

export default CreateCollectionPage
