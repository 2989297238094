import { navigate } from "gatsby"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { AppContext } from "../../AppContext"
import {
  InlineObject5BlockchainEnum,
  NewCollectionBlockchainEnum,
  UploadfileMIMEAddressMIMEEnum,
} from "../../core/api"
import { DialogTypeEnum } from "../../core/const/DialogTypeEnum"
import AlertContainer from "../../core/store/AlertContainer"
import WalletContainer from "../../core/store/WalletContainer"
import { CreateCollectionRequest } from "../../core/type/Request"
import { AxiosError, generateErrorMsg } from "../../core/utils/generateErrorMsg"
import { uploadS3 } from "../../core/utils/uploadS3"
import { validationNetwork } from "../../core/utils/validationNetwork"
import { CreateCollectionDetail } from "./CreateCollectionDetail"

export type CreateCollectionContainerProps = {}

export const CreateCollectionContainer: React.FunctionComponent<CreateCollectionContainerProps> =
  () => {
    const { t } = useTranslation()
    const { pushAlert, openModal, closeModal } = AlertContainer.useContainer()
    const { walletAddress, chainId } = WalletContainer.useContainer()
    const [loading, setLoading] = useState("")

    const createCollection = async (form: CreateCollectionRequest) => {
      setLoading(t("str_create_collection_step1_message"))
      try {
        console.info("create collection form", form)
        if (
          validationNetwork(
            chainId,
            form.blockchain as InlineObject5BlockchainEnum
          )
        ) {
          Promise.all([
            AppContext.apiExecutor.collectionImageuploadPost({
              walletAddress: walletAddress,
              MIME: form.logo_image.type as UploadfileMIMEAddressMIMEEnum,
            }),
            AppContext.apiExecutor.collectionImageuploadPost({
              walletAddress: walletAddress,
              MIME: form.feature_image.type as UploadfileMIMEAddressMIMEEnum,
            }),
            AppContext.apiExecutor.collectionImageuploadPost({
              walletAddress: walletAddress,
              MIME: form.banner_image.type as UploadfileMIMEAddressMIMEEnum,
            }),
          ])
            .then(([logo, feature, banner]) => {
              Promise.all([
                uploadS3(logo.data.s3PreSignedUrl || "", form.logo_image),
                uploadS3(feature.data.s3PreSignedUrl || "", form.feature_image),
                uploadS3(banner.data.s3PreSignedUrl || "", form.banner_image),
              ])
                .then(async () => {
                  setLoading(t("str_create_collection_step2_message"))
                  await AppContext.apiExecutor.collectionCreatePost({
                    ...form,
                    logo_image: logo.data.fileName,
                    feature_image: feature.data.fileName,
                    banner_image: banner.data.fileName,
                    walletAddress: walletAddress,
                    blockchain: form.blockchain as NewCollectionBlockchainEnum,
                  })
                  setLoading("")
                  openModal({
                    title: t("str_create_collection_success"),
                    content: t("str_create_collection_success_message"),
                    type: DialogTypeEnum.SUCCESS,
                    close: closeModal,
                    buttonRight: {
                      text: t("str_goto_create_nft"),
                      action: () => navigate("/create_nft"),
                    },
                  })
                })
                .catch(err => {
                  pushAlert({
                    message: `${err}`,
                    severity: "error",
                  })
                  setLoading("")
                })
            })
            .catch(err => {
              pushAlert({
                message: `${err}`,
                severity: "error",
              })
              setLoading("")
            })
        } else {
          setLoading("")
          openModal({
            title: t("str_network_error_title"),
            content: t("str_network_error_message", {
              chainId: chainId,
              blockchain: form.blockchain,
            }),
            type: DialogTypeEnum.WARNING,
            close: closeModal,
            buttonRight: { text: t("str_network_error_confirmBtn") },
          })
        }
      } catch (err) {
        setLoading("")
        pushAlert({
          message: generateErrorMsg(err as AxiosError),
          severity: "error",
        })
      }
    }

    return (
      <CreateCollectionDetail
        createCollection={createCollection}
        loading={loading}
      />
    )
  }
